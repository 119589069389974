export interface BreadcrumbConstructor {
    /** If defined, will render a breadcrumb in the header with this route */
    label?: string;
    /** Skips this node and the entire tree beneath it */
    skip?: boolean;
}

export class Breadcrumb {
    private static count = 0;
    public readonly id: string;
    public readonly label: string;
    public readonly skip: boolean;

    public constructor(input: string | BreadcrumbConstructor) {
        this.id = `${Breadcrumb.count++}`;
        if (typeof input === 'string') {
            this.label = input;
            this.skip = false;
        } else {
            this.label = '';
            this.skip = input.skip ?? false;
        }
    }
}
